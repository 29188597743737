var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, css, html } from 'lit';
import { customElement } from 'lit/decorators.js';
export let RootElement = class RootElement extends LitElement {
    render() {
        return html `<slot></slot>`;
    }
};
RootElement.styles = css `:host{margin:0;min-height:100vh;text-align:center;font-size:14px;padding:5em 1em 1em;box-sizing:border-box;font-family:sans-serif;font-style:normal;color:#1c1e21;display:flex;flex-flow:column nowrap;background:#f1eef5;background:linear-gradient(180deg,#f1eef5 68%,rgba(60,1,186,.17) 100%)}::slotted(h1){font-size:20px;line-height:32px;margin-block-start:.5em;margin-block-end:.5em}::slotted(h3){color:#181818;font-weight:400;font-size:14px;line-height:17px;margin-block-start:.5em;margin-block-end:.5em}::slotted(.transparent){opacity:.6}::slotted(.mb-3rem){margin-bottom:3rem}`;
RootElement = __decorate([
    customElement('ic-root')
], RootElement);
